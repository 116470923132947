<template>
	<CardContainer :title="title" :subtitle="subtitle" :showsAction="false">


		<div class="pt-2 grid gap-2 grid-cols-1 items-start">
			<!-- <div class="hidden md:block" v-if="locationValid">
				<StaticMap
					:longitude="this.location?.longitude"
					:latitude="this.location?.latitude"
					class="map-container rounded overflow-hidden aspect-h-12 aspect-w-16"
					@click="showMap"
				>
				</StaticMap>
			</div> -->
			<div
				class="text-info-container py-2 grid gap-4 md:gap-x-6 md:grid-cols-2 gird-cols-2"
			>
				<template v-for="item in displayItems" :key="item.title">
					<div>
						<h2 class="title">{{ item.title }}</h2>
						<template v-if="item.href">
							<a class="content" :href="item.href">{{ item.value }}</a>
						</template>
						<template v-else>
							<p class="content">
								{{ item.value }}
								<template v-if="item.type === 'address'">
							<a class="navigation-action" @click="showMap">一键导航</a>
							</template>
							</p>
						</template>
					</div>
				</template>
			</div>
			<div class="md:hidden" v-if="locationValid">
			<!-- <StaticMap
				:longitude="this.location?.longitude"
				:latitude="this.location?.latitude"
				class="map-container rounded overflow-hidden aspect-h-12 aspect-w-16"
				@click="showMap"
			>
			</StaticMap> -->
			</div>
		</div>
	</CardContainer>
</template>

<script>
import CardContainer from "@/components/cards/CardContainer.vue";
// import StaticMap from "@/components/cards/functional/StaticMap.vue";
import { $vfm } from "vue-final-modal";
import { GetSupportedMapActions } from "@/utils/map_utils.js";

export default {
	props: {
		address: String,
		location: Object,
		phone: String,
		email: String,
		contact: String,
		title: { type: String, default: "联系我们"},
		subtitle: { type: String},
	},
	name: "ContactCard",
	components: {
		CardContainer,
		// StaticMap,
	},
	data() {
		return {
			// title: "联系我们",
			mapActions: GetSupportedMapActions(),
			// contact: "杜灵芝",
			// location: {
			// 	latitude: 120.06668,
			// 	longitude: 30.29045,
			// },
			// address: "浙江省杭州市西湖区紫霞街176号杭州互联网创新创业园2号楼11楼",
			// phone: "400-62-96871",
			// email: "hezuo@ltd.com",
		};
	},
	computed: {
		showsMapNavigation: function() {
			return this.locationValid && this.mapActions?.length > 0;
		},
		displayItems: function() {
			let items = [];
			if (this.address && this.address.length > 0) {
				items.push({
					title: "联系地址",
					value: this.address,
					type: "address"
				});
			}
			if (this.contact && this.contact.length > 0) {
				items.push({
					title: "联系人",
					value: this.contact,
				});
			}
			if (this.email && this.email.length > 0) {
				items.push({
					title: "邮箱",
					value: this.email,
					href: "mailto:" + this.email,
				});
			}
			if (this.phone && this.phone.length > 0) {
				items.push({
					title: "联系电话",
					value: this.phone,
					href: "tel:" + this.phone,
				});
			}
			return items;
		},
		locationValid: function() {
			if (this.location) {
				let valid =
					this.location.latitude &&
					this.location.longitude &&
					(this.location?.latitude != 0 || this.location?.longtitude != 0);
				return valid;
			}
			return false;
		},
	},
	methods: {
		showMap: function() {
			if (this.locationValid && this.mapActions?.length > 0) {
				$vfm.show("mapAction", {
					longitude: this.location.longitude,
					latitude: this.location.latitude,
					address: this.address,
				});
			}
		},
	},
};
</script>

<style scoped>
a.navigation-action, a.content {
	color: var(--theme-text-color);
}
.navigation-action { 
	margin-left: 2px; 
	cursor: pointer;
}
.content {
	font-size: 14px;
	margin-top: 2px;
	font-weight: 400;
}
.title {
	font-size: 13px;
	opacity: 0.6;
	font-weight: 500;
}


</style>
